import * as THREE from 'three'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js'
import GUI from 'lil-gui'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'
import holographicVertexShader from './shaders/holographic/vertex.glsl'
import holographicFragmentShader from './shaders/holographic/fragment.glsl'
import { DRACOLoader } from 'three/examples/jsm/Addons.js'

// Audio setup
const audio = new Audio("intro.mp3");



// invite dom element
const invite = document.querySelector('.invite')
const opener = document.querySelector('.open')

//textures
const textureLoader = new THREE.TextureLoader()

const inviteImage = textureLoader.load('invite2.png')
const normal = textureLoader.load('normal.jpeg')
normal.wrapS = THREE.RepeatWrapping;
normal.wrapT = THREE.RepeatWrapping;
normal.repeat.set( 15, 15 );
const normal2 = textureLoader.load('cloth.jpeg')
normal2.wrapS = THREE.RepeatWrapping;
normal2.wrapT = THREE.RepeatWrapping;
normal2.repeat.set( 100, 100 );


console.log(inviteImage)

/**
 * Base
 */
// Debug
// const gui = new GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Loaders
const gltfLoader = new GLTFLoader()
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
dracoLoader.setDecoderConfig({ type: 'js' })
gltfLoader.setDRACOLoader(dracoLoader);

const cube = new THREE.Mesh(
    new THREE.BoxGeometry(1,1,1),
    new THREE.MeshStandardMaterial()
)
cube.castShadow = true
// scene.add(cube)
//lights

const light = new THREE.SpotLight(0xffffff, 6, 100)
light.position.set(0.5, 3, -0.5)
light.castShadow = true;
light.shadow.mapSize.width = 1024; // default
light.shadow.mapSize.height = 1024; // default
light.shadow.camera.near = 0.1; // default
light.shadow.camera.far = 500; // default
scene.add(light)

const directionalLight = new THREE.DirectionalLight(0xffffff, 3.0)
directionalLight.castShadow = true

directionalLight.shadow.mapSize.width = 1024
directionalLight.shadow.mapSize.height = 1024

directionalLight.shadow.camera.near = 1
directionalLight.shadow.camera.far = 6

directionalLight.shadow.camera.top = 2
directionalLight.shadow.camera.right = 2
directionalLight.shadow.camera.bottom = - 2
directionalLight.shadow.camera.left = - 2

directionalLight.position.set(2, 2, - 1)

scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
let mobile = sizes.height > sizes.width

window.addEventListener('resize', () => {
    //update mobile
    mobile = sizes.height > sizes.width

    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(25, sizes.width / sizes.height, 0.1, 100)
// camera.position.set(0, 5, 0)
camera.position.set(1, 3,2)
camera.lookAt(0, 0, 0)
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const rendererParameters = {}
rendererParameters.clearColor = '#1d1f2a'

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.shadowMap.enabled = true;
renderer.setClearColor(rendererParameters.clearColor)
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// gui
//     .addColor(rendererParameters, 'clearColor')
//     .onChange(() =>
//     {
//         renderer.setClearColor(rendererParameters.clearColor)
//     })

/**
 * Material
 */

const materialParameters = {}
materialParameters.color = "#70c1ff"

// gui.addColor(materialParameters,'color')
// .onChange(()=>{
//     material.uniforms.uColor.value.set(materialParameters.color)
// })

const material = new THREE.ShaderMaterial({
    vertexShader: holographicVertexShader,
    fragmentShader: holographicFragmentShader,
    uniforms: {
        uTime: new THREE.Uniform(0.0),
        uColor: new THREE.Uniform(new THREE.Color(materialParameters.color))
    },
    transparent: true,
    side: THREE.DoubleSide,
    depthWrite: false,
    blending: THREE.AdditiveBlending
})

/**
 * Objects
 */
// Torus knot
const plane = new THREE.Mesh(
    new THREE.PlaneGeometry(10,10),
    new THREE.MeshStandardMaterial({side: THREE.DoubleSide, color: 0xffffff})
)
plane.material.normalMap = normal2
plane.position.y = 0.01
plane.rotation.x = -Math.PI *.5
plane.receiveShadow = true
scene.add(plane)

// Sphere
const sphere = new THREE.Mesh(
    new THREE.SphereGeometry(),
    material
)
sphere.position.x = - 3
// scene.add(sphere)

// Suzanne
let tiara = null
let gf = null
let mixer = null
let flower = null
const material2 = new THREE.MeshStandardMaterial({ 
    metalness: 0.9, 
    roughness: 0.6, 
    color: 0xff3366 })
const material3 = new THREE.MeshStandardMaterial({ 
    metalness: 0.9, 
    roughness: 0.6, 
    color: 0xbb8833 })
    material3.normalMap = normal
const material4 = new THREE.MeshBasicMaterial()
material4.map = inviteImage
gltfLoader.load(
    './flower.glb',
    (gltf) => {
        flower = gltf.scene
        flower.traverse((child) => {
            if (child.isMesh)
                child.castShadow = true;
                child.receiveShadow = true;
                child.material = material2
        })
        flower.scale.set(0.5, 0.5, 0.5)
        flower.rotation.x = Math.PI * 1.75
        mobile ? flower.position.set(0.3, 0.0, 0.55) : flower.position.set(0.7, 0.0, 0.6)
        scene.add(flower)

        let flower2 = flower.clone()
flower2.scale.set(0.7, 0.7, 0.7)
flower2.rotation.x = Math.PI * 1.75
flower2.position.set(0.51, 0.0, 0.5)
scene.add(flower2)

let flower3 = flower.clone()
flower3.scale.set(0.8, 0.8, 0.8)
        flower3.rotation.x = Math.PI * 1.75
        mobile ? flower3.position.set(0.25, 0.0, 0.8) : flower3.position.set(0.8, 0.0, 0.4)
        scene.add(flower3)

        let flower4 = flower.clone()
        flower4.scale.set(0.5, 0.5, 0.5)
        flower4.rotation.x = Math.PI * 1.75
        flower4.position.set(0.5, 0.0, -0.5)
        scene.add(flower4)

        let flower5 = flower.clone()
        mobile? flower5.scale.set(1, 1, 1) : flower5.scale.set(0.6, 0.6, 0.6)
        flower5.rotation.x = Math.PI * 1.75
        mobile ? flower5.position.set(0.6, 0.0, 0.8) : flower5.position.set(-0.4, 0.0, 0.9)
        scene.add(flower5)

        
    }
)


gltfLoader.load(
    './tiara.glb',
    (gltf) => {
        tiara = gltf.scene
        tiara.traverse((child) => {
            if (child.isMesh)
                child.castShadow = true;
            child.receiveShadow = true;
        })
        tiara.scale.set(8, 8, 8)
        mobile? tiara.position.set(-0.9,0.02,-1.1) :tiara.position.set(-1.0,0.02,-0.2)
        tiara.rotation.y = -Math.PI * 1.75
        scene.add(tiara)
        console.log(tiara)
    }
)




gltfLoader.load('envelope.glb', function (gltf) {
    gf = gltf.scene;
    const animations = gltf.animations
    gf.traverse(function (object) {
        if (object.isMesh) {
            object.castShadow = true;
            object.receiveShadow = true;
            console.log(object)

        }
        if(object.name != "Plane" && object.name != "Plane003")

            object.material = material3
            if(object.name == "Plane003")

            object.material = material4



    });
    gf.scale.set(0.5, 0.5, 0.5)
    // gf.rotation.y = Math.PI /2
    gf.position.z = 0

    mixer = new THREE.AnimationMixer(gf)


    canvas.addEventListener('tap', () => {
        opener.classList.add('fade')
        if (audio.paused) {
            audio.play();
        } else {
            audio.pause();
        }

        animations.forEach(animation => {
            mixer.clipAction(animation).play()
            mixer.clipAction(animation).setLoop(THREE.LoopOnce);
            mixer.clipAction(animation).clampWhenFinished = true;
        })
        setTimeout(() => {

            invite.classList.add('slide')
            
        }, 3000)

    })
    canvas.addEventListener('click', () => {
        opener.classList.add('fade')

        if (audio.paused) {
            audio.play();
        } else {
            audio.pause();
        }
        animations.forEach(animation => {
            mixer.clipAction(animation).play()
            mixer.clipAction(animation).setLoop(THREE.LoopOnce);
            mixer.clipAction(animation).clampWhenFinished = true;
        })
        setTimeout(() => {

            invite.classList.add('slide')
        }, 3000)

    })



    scene.add(gf);

});

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0
const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    //update material
    material.uniforms.uTime.value = elapsedTime
    if (mixer) {
        mixer.update(deltaTime)
    }

    // // Rotate objects
    // if(suzanne)
    // {
    //     suzanne.rotation.x = - elapsedTime * 0.1
    //     suzanne.rotation.y = elapsedTime * 0.2
    // }

    // sphere.rotation.x = - elapsedTime * 0.1
    // sphere.rotation.y = elapsedTime * 0.2

    // torusKnot.rotation.x = - elapsedTime * 0.1
    // torusKnot.rotation.y = elapsedTime * 0.2

    // Update controls
    // controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()